import * as React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";

const Quote = ({ quote }) => {
  const styleItalics = {
    fontStyle: "italic",
    margin: 0,
  };

  const styleAttribution = {
    textAlign: "end",
  };

  const styleAvatar = { maxHeight: "6rem", minHeight: "4rem" };

  function floatBlockQuote() {
    return quote.reverseLayout ? "right" : "left";
  }

  const colAvatar = (
    <Col key={"avatar"} xs="auto">
      <Image
        fluid
        src={quote.author.image}
        roundedCircle
        style={styleAvatar}
        alt={"Quote from: " + quote.author.name}
      />
    </Col>
  );

  const colQuote = (
    <Col key={"quote"}>
      <Container fluid>
        <blockquote
          style={{ float: floatBlockQuote() }}
          className="blockquote quote"
        >
          <div style={styleItalics}>
            {quote.text.map((str, i) => {
              if (i === 0) {
                return (
                  <p key={i} style={{ margin: 0 }}>
                    {" "}
                    {'"' + str}{" "}
                  </p>
                );
              } else if (i === quote.text.length - 1) {
                return (
                  <p key={i} style={{ margin: 0, paddingLeft: "4px" }}>
                    {" "}
                    {str + '"'}
                  </p>
                );
              }
              return (
                <p key={i} style={{ margin: 0, paddingLeft: "4px" }}>
                  {str}
                </p>
              );
            })}
          </div>
          <figcaption
            className="blockquote-footer text-end"
            style={styleAttribution}
          >
            {quote.author.name}&nbsp;
            {"("}
            <span style={styleItalics}>{quote.author.title}</span>
            {")"}
          </figcaption>
        </blockquote>
      </Container>
    </Col>
  );

  var quoteRow = [colAvatar, colQuote];

  if (quote.reverseLayout) {
    quoteRow.reverse();
  }

  return (
    <Container
      fluid
      data-sal={"slide-" + floatBlockQuote()}
      data-sal-duration={300}
    >
      <figure>
        <Row>{quoteRow}</Row>
      </figure>
    </Container>
  );
};

export default Quote;
