import * as React from "react";
import { Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";
import Socials from "./Socials";

function AboutUs() {
  const styleLogos = {
    //all logos need to be treated equally as their brand guidelines say
    display: "inline-block",

    height: 14,
    margin: "0 8px", //doubling it because it looks better; instagram requires half of its logo space to be away from another element.
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div
      data-sal="slide-down"
      data-sal-duration="500"
      data-sal-ease="easeInOutSine"
      className="text-center"
      style={{ width: "100%" }}
    >
      <h2 style={{ paddingTop: "1rem" }} className="serif text-center">
        <span id="contact-us">Contact Us </span>
      </h2>
      <div>
        <a
          href="mailto:usydcommstemsociety@gmail.com"
          style={{
            textDecoration: "none",
            color: "white",
            margin: 0,
          }}
        >
          <Image
            src="../../static/logos/email.webp"
            alt="email icon"
            style={{
              ...styleLogos,
              width: "auto",
            }}
          ></Image>
          usydcommstemsociety@gmail.com
        </a>
      </div>
      <Socials style={{ padding: 0 }} />
    </div>
  );
}

export default AboutUs;
