import React from "react";

function Socials() {
  const styleLogos = {
    //all logos need to be treated equally as their brand guidelines say
    display: "inline-block",
    width: 32, //counter the padding
    height: 32,
    margin: "0 16px", //doubling it because it looks better; instagram requires half of its logo space to be away from another element.
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div
      id="socials"
      style={{
        padding: "1rem 0",
        paddingLeft: "5.6px",
      }}
    >
      {/* could be a map but its trouble if you change a logo out of spec and i doubt urls will change  */}
      <a
        style={{
          ...styleLogos,
          backgroundImage: "url('../../static/logos/facebookSmall.webp')",
        }}
        aria-label="CommStem Facebook Page Link"
        href="https://www.facebook.com/usydcommstemsociety"
        target="_blank"
        rel="noreferrer"
      >
        &nbsp;
      </a>
      <a
        style={{
          ...styleLogos,

          backgroundImage: "url('../../static/logos/igColor.webp')",
        }}
        aria-label="CommStem Instagram Page Link"
        href="https://www.instagram.com/usydcommstemsociety/"
        target="_blank"
        rel="noreferrer"
      >
        &nbsp;
      </a>
      <a
        style={{
          ...styleLogos,
          width: "37.63px",
          backgroundImage: "url('../../static/logos/linkedIn.webp')",
          //a bit iffy since the (R) is supposed to be clearly seen. but i am over their minimum logo size spec
        }}
        aria-label="CommStem LinkedIn Page Link"
        href="https://www.linkedin.com/company/usyd-comm-stem"
        target="_blank"
        rel="noreferrer"
      >
        &nbsp;
      </a>
    </div>
  );
}
export default Socials;
