import React from "react";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";
import data from "../../public/static/data/executives.json";
import Executive from "./Executive.js";

const ExecutiveList = () => {
  const executives = data.executives;

  const styleExecutivesBox = {
    paddingTop: "1rem",
  };

  return (
    <div
      data-sal="slide-down"
      data-sal-duration="500"
      data-sal-ease="easeInOutSine"
      style={styleExecutivesBox}
    >
      <h2 style={{ margin: 0 }} className="serif text-center">
        <span id="team" className="anchor">
          Meet the Team
        </span>
      </h2>
      <Row style={{ width: "100%" }} className="justify-content-center">
        {executives.map((exec, index) => {
          return (
            <Col
              key={index}
              xs="4"
              sm="3"
              md="2"
              style={{ minwidth: 156, maxWidth: 156 }}
            >
              <Executive exec={exec} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default ExecutiveList;
