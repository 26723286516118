import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";
import { Carousel, Image, Container } from "react-bootstrap";
import data from "../../public/static/data/events.json";

const EventList = () => {
  const events = data.events;

  const styleCarouselImage = {
    width: "100%",
    height: "24rem",
    objectFit: "contain",
  };

  const styleTitle = {
    top: 0,
    fontSize: "4rem",
    textShadow: "2px 2px 5px #000000",
  };

  const styleSubtitle = {
    fontSize: "1rem",
    textShadow: "1px 1px 3px #000000",
  };

  return (
    <div
      data-sal="slide-down"
      data-sal-duration="500"
      data-sal-ease="easeInOutSine"
      className="altBgColor"
      style={{ width: "100%", padding: "1rem 0rem" }}
    >
      <h2 style={{ paddingBottom: "1rem" }} className="serif text-center">
        <span id="events" className="anchor">
          Events
        </span>
      </h2>
      <Container>
        <Carousel
          // activeIndex={0} //for testing
          style={{ height: "24rem" }}
          className="justify-content-center "
        >
          {events.map((event, index) => {
            return (
              <Carousel.Item key={index} style={{ overflow: "hidden" }}>
                <a href={event.url}>
                  <Image
                    src={"../../static/images/" + event.image}
                    className="d-block w-100"
                    style={{
                      ...styleCarouselImage,
                    }}
                    alt={event.name}
                  />
                  <Carousel.Caption style={styleTitle}>
                    <h3 className="serif">{event.name}</h3>
                  </Carousel.Caption>
                  <Carousel.Caption style={styleSubtitle}>
                    <p>{event.description}</p>
                  </Carousel.Caption>
                </a>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Container>
      &nbsp;
    </div>
  );
};

export default EventList;
