import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";
import { Container } from "react-bootstrap";
import NavigationBar from "../components/NavigationBar.js";
import Head from "../components/Head";
import SponsorList from "../components/SponsorList";
import AboutUs from "../components/AboutUs";
import CCoverImage from "../components/CoverImage";
import ExecutiveList from "../components/ExecutiveList";
import QuoteList from "../components/QuoteList";
import EventList from "../components/EventList";

const IndexPage = () => {
    return (
        <div id="root">
            <Head />

            <Container id="main" fluid="xl">
                <NavigationBar />
                <CCoverImage />
                <QuoteList />
                <EventList />
                <ExecutiveList />
                <SponsorList />
                <AboutUs />
            </Container>
        </div>
    );
};

export default IndexPage;
