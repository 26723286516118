import * as React from "react";
import Helmet from "react-helmet";

export default function Head() {
  return (
    <div
      className="application"
      style={{
        position: "absolute",
      }}
    >
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta
          charSet="utf-8"
          name="description"
          content="Welcome to the University of Sydney COMM-STEM Society, Sections: joining form, quotes, events, executives, sponsors, socials links including Facebook, LinkedIn, Instagram and email"
        />
        <meta
          name="google-site-verification"
          content="_-HY4_TtEL7gIqbNfkrGqozQiv0bUN6Ubmi-LHRUaN4"
        />
        <link rel="icon" href="../../static/logos/commStemWhiteSmall.webp" />

        <title>
          Comm-Stem Society: A society for University of Sydney commerce / STEM
          students
        </title>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "COMM-STEM Society",
            "legalName": "University of Sydney COMM-STEM Society.",
            "url": "http://www.usydcommstem.com.au",
            "logo": "https://www.usydcommstem.com.au/static/logos/commStemWhite.webp",
            "foundingDate": "2020",
            "founders": [
              {
                "@type": "Person",
                "name": "Daniel Anthonisz",
                "jobTitle": "Co-President",
                "url": "https://www.linkedin.com/in/dan-anthonisz/"
              },
              {
                "@type": "Person",
                "name": "Marcus Manduci",
                "jobTitle": "Co-President",
                "url": "https://www.linkedin.com/in/marcusmanduci/"
              }
            ],
            "contactPoint": {
              "@type": "ContactPoint",
              "email": "usydcommstemsociety@gmail.com"
            },
            "sameAs": [
              "https://www.facebook.com/usydcommstemsociety",
              "https://www.instagram.com/usydcommstemsociety/",
              "https://www.linkedin.com/company/usyd-comm-stem/"
            ],
            "location": {
            	"@type" : "place",
                "name": "Sydney, Australia"
            },
            "employee": [
            {
                "@type": "Person",
                "name": "Richard Brown",
                "jobTitle": "Fullstack Developer",
                "url" : "https://github.com/RichardBrown1", "sameAs" : [ "https://www.linkedin.com/in/richard-h-brown/"]
            },
            {
                "@type": "Person",
                "name": "Nam Dao",
                "jobTitle": "Operations Director",
                "url" : "https://www.linkedin.com/in/namdao-au/"
            }
              ]
          }`}
        </script>
      </Helmet>
    </div>
  );
}
