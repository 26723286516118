import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";
import { Row, Col } from "react-bootstrap";
import data from "../../public/static/data/sponsors.json";
import Sponsor from "./Sponsor.js";

const SponsorList = () => {
  const sponsors = data.sponsors;

  return (
    <div
      data-sal="slide-down"
      data-sal-duration="500"
      data-sal-ease="easeInOutSine"
      className="altBgColor"
      style={{ width: "100%", paddingTop: "1rem" }}
    >
      <h2 className="serif text-center">
        <span id="partners" className="anchor">
          Thanks to our Sponsors and Partners
        </span>
      </h2>
      <Row style={{ width: "100%" }} className="justify-content-center">
        {sponsors.map((sponsor, index) => {
          return (
            <Col
              key={index}
              xs="6"
              md="4"
              style={{
                minWidth: 256,
                maxWidth: 256,
              }}
            >
              <Sponsor sponsor={sponsor} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SponsorList;
