import React from "react";
import { Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";

const Executive = ({ exec }) => {
  const styleTitle = {
    fontSize: "1rem",
    color: "whitesmoke",
    margin: "0.3rem 0",
  };

  const styleSubtitle = {
    fontSize: "0.9rem",
    fontWeight: 200,
    color: "#ffffff",
    margin: "0.3rem 0",
  };

  const styleCard = {
    minWidth: "72px",
    minHeight: "215px",
    backgroundColor: "#0f0f0f",
    margin: "1rem 0",
    padding: "0 0",
    textAlign: "center",
  };

  return (
    <Card style={styleCard}>
      <a
        className="card-img hoverHitbox"
        href={exec.linkedIn}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="blurOnHover"
          alt={exec.name}
          src={"../../static/avatars/" + exec.image}
          style={{ width: "100%" }}
        />
        <img
          className="showOnHover"
          src={"../../static/logos/linkedIn.webp"}
          alt={exec.name + "'s LinkedIn"}
          style={{
            width: "64px",
            left: "calc(50% + 5px )",
            top: "64px",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            padding: "auto",
          }}
        />
      </a>
      {/* <div style={styleTextDiv}> */}
      <Card.Title className="sansSerif" style={styleTitle}>
        {exec.name}
      </Card.Title>
      <div>
        <Card.Subtitle style={styleSubtitle}>{exec.title}</Card.Subtitle>
      </div>
    </Card>
  );
};

export default Executive;
