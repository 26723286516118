import * as React from "react";
import { Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";
import Quote from "./Quote.js";
import data from "../../public/static/data/quotes.json";

const QuoteList = () => {
    const quotes = data.quotes;

    return (
        <Container fluid>
            <figure id="quotes">
                {quotes.map((quote, index) => {
                    return (
                        <Row key={index}>
                            <Quote quote={quote} />
                        </Row>
                    );
                })}
            </figure>
        </Container>
    );
};

export default QuoteList;
