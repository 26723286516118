import React from "react";
import { Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";

const Sponsor = ({ sponsor }) => {
  const styleTitle = {
    fontSize: "1rem",
    color: "whitesmoke",
    margin: "0.3rem 0",
  };

  const styleCard = {
    minWidth: "144px",
    backgroundColor: "#0f0f0f",
    margin: "1rem 0",
    padding: "0 0",
    textAlign: "center",
  };

  return (
    <Card style={styleCard}>
      <a
        className="card-img hoverHitbox"
        href={sponsor.website}
        target="_blank"
        rel="noreferrer"
      >
        <Card.Img
          style={{ width: "100%", height: 112, objectFit: "cover" }}
          src={"../../static/sponsors/" + sponsor.logo}
          loading={"lazy"}
          alt={sponsor.name}
        ></Card.Img>
      </a>

      <Card.Title className="sansSerif" style={styleTitle}>
        {sponsor.name}{" "}
      </Card.Title>
    </Card>
  );
};

export default Sponsor;
