import * as React from "react";
import { Jumbotron, Container, Button, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";
import Socials from "./Socials";

function CoverImage() {
  const styleJumbotron = {
    paddingTop: 0,
    paddingBottom: 0,
  };

  const styleJumbotronContainer = {
    padding: 0,
    overflow: "visible",
    backgroundColor: "black",
    backgroundImage: "url(../../static/images/coverImage.webp)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const styleTitles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "16rem", //has to be same height as cover image to be centered
  };

  const styleSubtitle = {
    fontSize: "1rem",
    textShadow: "1px 1px 3px #000000",
  };

  const styleJoinUs = {
    backgroundColor: "#AA00ff",
    borderColor: "#AA00FF",

    fontSize: 18,
  };

  return (
    <Jumbotron fluid style={styleJumbotron}>
      <Container fluid style={styleJumbotronContainer}>
        <div className="text-center serif" style={styleTitles}>
          <div>
            <Container>
              <Image
                style={{ maxHeight: "12rem" }}
                fluid
                src="../../static/logos/commStemWhite.webp"
                alt="Comm-Stem Hero Logo"
              ></Image>
              <p style={styleSubtitle}>
                A Society for University of Sydney's STEM-Commerce Double Degree
                Students
              </p>
            </Container>

            <Socials />
            <div className="sansSerif" style={{ padding: "1rem" }}>
              <Button
                href="https://l.facebook.com/l.php?u=https%3A%2F%2Fforms.gle%2FzM11MoeV93SCNr3s8%3Ffbclid%3DIwAR1V60twx7769RmVyki9pfvkf9Ccuh_neWixy9pW9ISwhRmwSvyHTEL91Po&h=AT25rYd1V3L-cHYeXe0SqwIF8qdZ0bZv3ya5wUMt5HOdC0IxSGzEAnBH-4G-QiBbndmff1_q5DBGVEsnttjiIAKaC_s1KSRwu0PaBOgYdXIIIKxnGJTtjSWLbXFWyJfEwOHvCdgSzA"
                style={styleJoinUs}
                target="_blank"
                rel="noreferrer"
              >
                Join Us
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Jumbotron>
  );
}

export default CoverImage;
