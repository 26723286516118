import * as React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/theme.css";

function NavigationBar() {
  const styleBrand = {
    fontSize: "28px",
    fontWeight: 800,
    color: "whitesmoke",
    padding: "0",
    margin: 0,
  };

  const styleNavBar = {
    backgroundColor: "black",
  };

  const styleNavLink = {
    padding: "0.5rem clamp(1.1rem, 1.5rem, 1.5rem)",
  };

  const styleNavItem = {
    listStyleType: "none",
  };

  const styleBrandLogo = {
    height: 36,
    marginRight: "0.5rem",
    position: "relative",
    top: "-3px",
  };
  return (
    <Navbar
      className="bg-black sticky-top"
      expand="md"
      variant="dark"
      style={styleNavBar}
    >
      <Navbar.Brand href="#root" className="serif" style={{ ...styleBrand }}>
        <img
          alt="COMM-STEM Logo"
          style={styleBrandLogo}
          src="../../static/logos/commStemWhiteSmall.webp"
        ></img>
        COMM-STEM
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto" as="ul">
          <Nav.Item as="li" style={styleNavItem}>
            <Nav.Link style={styleNavLink} href="#events">
              Events
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" style={styleNavItem}>
            <Nav.Link style={styleNavLink} href="#team">
              Team
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" style={styleNavItem}>
            <Nav.Link style={styleNavLink} href="#partners">
              Partners
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" style={styleNavItem}>
            <Nav.Link
              style={{ ...styleNavLink, overflow: "hidden" }}
              href="#contact-us"
            >
              Contact Us
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
